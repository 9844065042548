import m from 'moment';

import { JwtToken } from '@bp/shared/utilities/core';
import { Role } from '@bp/shared/domains/roles';
import { BridgerAdminFeature, MerchantAdminFeature } from '@bp/shared/domains/permissions';

import { IdentityJwtSessionDTO } from './identity-jwt-session';
import { DEFAULT_JWT_ISSUER, DEFAULT_JWT_STAGING_AUDIENCE } from './jwt-auth-params.consts';

export function getNeverExpiringEpoch(): number {
	return m().add(1, 'week')
		.unix();
}

export enum MockUserEmail {

	Demo = 'demo@demo.com',

	NoSubscription = 'no-subscription@mock.com',

	Free = 'free-mock@mock.com',

	Startup = 'startup-mock@mock.com',

	Pro = 'pro-mock@mock.com',

	ProEmailOtp = 'pro-email-otp@mock.com',

	Enterprise = 'enterprise-mock@mock.com',

	EnterpriseUnpaid = 'enterprise-unpaid-mock@mock.com',

	FreeBlocked = 'free-blocked@mock.com',

	BridgerAdmin = 'bridger-admin@mock.com',

	BridgerPspsManager = 'bridger-psps-manager@mock.com',

	BridgerPspsPublicInfoManager = 'bridger-psps-public-info-manager@mock.com',

	SupportAgentTier0 = 'support-agent-tier0@mock.com',

	SupportAgentTier1 = 'support-agent-tier1@mock.com',

	SupportAgentTier2 = 'support-agent-tier2@mock.com',

}

export const MOCK_DEMO_USER_CREDENTIALS = {
	email: MockUserEmail.Demo,
	password: 'awesomedemo',
};

export const MOCK_USER_EMAILS = Object.values(MockUserEmail);

export const getNoSubscriptionMockUserCredentials = (): IdentityJwtSessionDTO => ({
	userIdentityId: '6cefe991-a0d9-4169-b361-8529a5b8f27f',
	organizationId: 'bd313fe4-a7c4-43e0-9edb-b64d9b729ac1',
	wizardSetupId: '35bef20c-44c4-41f0-947d-634f673e73f6', // to represent the owner of the merchant
	userFullName: 'No Subscription',
	userEmail: MockUserEmail.NoSubscription,
	exp: getNeverExpiringEpoch(),
	issuedAt: m().unix(),
	sub: '6cefe991-a0d9-4169-b361-8529a5b8f27f',
	aud: DEFAULT_JWT_STAGING_AUDIENCE,
	iss: DEFAULT_JWT_ISSUER,
	permissions: [
		`${ MerchantAdminFeature.changePassword }`,
		`${ MerchantAdminFeature.subscriptionsManagement }`,
		`${ MerchantAdminFeature.subscriptionsManagement.getActionClaim('edit') }`,
	],
	roles: [ Role.merchantOwner.internalName ],
});

export const getFreeMockUserCredentials = (): IdentityJwtSessionDTO => ({
	userIdentityId: '6cefe991-a0d9-4169-b361-8529a5b8f27f',
	organizationId: 'bd313fe4-a7c4-43e0-9edb-b64d9b729ac1',
	wizardSetupId: '35bef20c-44c4-41f0-947d-634f673e73f6', // to represent the owner of the merchant
	userFullName: 'Free User',
	userEmail: MockUserEmail.Free,
	exp: getNeverExpiringEpoch(),
	issuedAt: m().unix(),
	sub: '6cefe991-a0d9-4169-b361-8529a5b8f27f',
	aud: DEFAULT_JWT_STAGING_AUDIENCE,
	iss: DEFAULT_JWT_ISSUER,
	permissions: [
		`${ MerchantAdminFeature.dashboard }`,
		`${ MerchantAdminFeature.changePassword }`,
		`${ MerchantAdminFeature.reports }.otp_required__short_ttl`,
		`${ MerchantAdminFeature.reports.getActionClaim('preview') }`,
		`${ MerchantAdminFeature.reports.getActionClaim('dateRangeLimitAllTime') }`,
		`${ MerchantAdminFeature.reports.getActionClaim('dateRangeLimitPreviousPlusCurrentYear') }`,
		`${ MerchantAdminFeature.reports.getActionClaim('export') }`,
		`${ MerchantAdminFeature.checkouts }`,
		`${ MerchantAdminFeature.checkouts.getActionClaim('create') }`,
		`${ MerchantAdminFeature.checkouts.getActionClaim('edit') }`,
		`${ MerchantAdminFeature.transactions }`,
		`${ MerchantAdminFeature.transactions.getActionClaim('refund') }.paywall`,
		`${ MerchantAdminFeature.transactions.getActionClaim('capture') }.otp_required__short_ttl`,
		`${ MerchantAdminFeature.transactions.getActionClaim('void') }`,
		`${ MerchantAdminFeature.paymentRoutes }`,
		`${ MerchantAdminFeature.paymentRoutes.getActionClaim('create') }`,
		`${ MerchantAdminFeature.paymentRoutes.getActionClaim('edit') }`,
		`${ MerchantAdminFeature.paymentRouteCredentials }`,
		`${ MerchantAdminFeature.paymentRouteCredentials.getActionClaim('create') }`,
		`${ MerchantAdminFeature.paymentRouteCredentials.getActionClaim('edit') }`,
		`${ MerchantAdminFeature.paymentRouteRules }.paywall`,
		`${ MerchantAdminFeature.paymentRouteRules.getActionClaim('create') }`,
		`${ MerchantAdminFeature.paymentRouteRules.getActionClaim('edit') }`,
		`${ MerchantAdminFeature.virtualTerminal }`,
		`${ MerchantAdminFeature.payouts }.paywall`,
		`${ MerchantAdminFeature.payouts.getActionClaim('create') }`,
		`${ MerchantAdminFeature.creditCardTokens }.paywall`,
		`${ MerchantAdminFeature.creditCardTokens.getActionClaim('edit') }`,
		`${ MerchantAdminFeature.creditCardTokens.getActionClaim('sneakPeek') }.otp_required__single_use`,
		`${ MerchantAdminFeature.pos }`,
		`${ MerchantAdminFeature.psps }`,
		`${ MerchantAdminFeature.psps.getActionClaim('create') }.paywall`,
		`${ MerchantAdminFeature.psps.getActionClaim('edit') }`,
		`${ MerchantAdminFeature.pspCredentials }`,
		`${ MerchantAdminFeature.pspCredentials.getActionClaim('create') }`,
		`${ MerchantAdminFeature.pspCredentials.getActionClaim('edit') }`,
		`${ MerchantAdminFeature.members }`,
		`${ MerchantAdminFeature.members.getActionClaim('invite') }.paywall`,
		`${ MerchantAdminFeature.members.getActionClaim('edit') }`,
		`${ MerchantAdminFeature.requestSupport }`,
		`${ MerchantAdminFeature.requestSupport.getActionClaim('edit') }`,
		`${ MerchantAdminFeature.billing }`,
		`${ MerchantAdminFeature.subscriptionsManagement }`,
		`${ MerchantAdminFeature.subscriptionsManagement.getActionClaim('edit') }`,
		`${ MerchantAdminFeature.paywith }`,
		`${ MerchantAdminFeature.newDesign }`,
		`${ MerchantAdminFeature.bpThreeDs }`,
	],

	roles: [ Role.merchantOwner.internalName ],
});

export const getFreeBlockedUserCredentials = (): IdentityJwtSessionDTO => ({
	...getFreeMockUserCredentials(),
	organizationId: '876dadba-dda5-45de-8335-3217ff2e692e',
	userFullName: 'Free Blocked User',
	userEmail: MockUserEmail.FreeBlocked,
});

export const getStartupUserCredentials = (): IdentityJwtSessionDTO => ({
	...getFreeMockUserCredentials(),
	organizationId: '6359c4dc-480e-4b3d-960d-9a2a18c7b99b',
	userFullName: 'Startup User',
	userEmail: MockUserEmail.Startup,
});

export const getProMockUserCredentials = (): IdentityJwtSessionDTO => ({
	...getFreeMockUserCredentials(),
	organizationId: 'e423691c-5879-4aaf-a8cc-6e87b6761673', // used in mock seed data
	userFullName: 'Pro User',
	userEmail: MockUserEmail.Pro,
	permissions: getFreeMockUserCredentials()
		.permissions!
		.map(permission => permission.replace('.paywall', '')),
});

export const getProEmailOtpMockUserCredentials = (): IdentityJwtSessionDTO => ({
	...getProMockUserCredentials(),
	userFullName: 'Pro Email Otp User',
	userEmail: MockUserEmail.ProEmailOtp,
	otpProvider: 'email',
});

export const getEnterpriseMockUserCredentials = (): IdentityJwtSessionDTO => ({
	...getProMockUserCredentials(),
	organizationId: '38091e0d-5a52-41b5-b74d-6403dd566ed8',
	userFullName: 'Enterprise User',
	userEmail: MockUserEmail.Enterprise,
});

export const getEnterpriseUnpaidMockUserCredentials = (): IdentityJwtSessionDTO => ({
	...getProMockUserCredentials(),
	organizationId: 'bb725202-6d35-45cc-ad25-c658f244d6ed',
	userFullName: 'Enterprise Unpaid User',
	userEmail: MockUserEmail.EnterpriseUnpaid,
	permissions: [
		`${ MerchantAdminFeature.changePassword }`,
		`${ MerchantAdminFeature.enterpriseSubscriptionCheckout }`,
	],
});

export const getDemoMockUserCredentials = (): IdentityJwtSessionDTO => ({
	...getProMockUserCredentials(),
	userIdentityId: 'e2ffd316-9a72-42cc-bb0b-05409435359a',
	userFullName: 'Demo User',
	userEmail: MockUserEmail.Demo,
	exp: getNeverExpiringEpoch(),
	permissions: getProMockUserCredentials().permissions!
		.map(permission => permission.replace(/\.(paywall|otp_required__short_ttl|otp_required__single_use)/u, '')),
});

export const getBridgerPspsManagerMockCredentials = (): IdentityJwtSessionDTO => ({
	userIdentityId: '15976b77-291b-40d2-bd63-6f1bf3fa33b0',
	organizationId: 'bd313fe4-a7c4-43e0-9edb-b64d9b729ac1',
	userFullName: 'Bridger Psps Manager',
	userEmail: MockUserEmail.BridgerPspsManager,
	exp: getNeverExpiringEpoch(),
	sub: '15976b77-291b-40d2-bd63-6f1bf3fa33b0',
	aud: DEFAULT_JWT_STAGING_AUDIENCE,
	iss: DEFAULT_JWT_ISSUER,
	permissions: [
		`${ BridgerAdminFeature.baBridgerPsps }`,
		`${ BridgerAdminFeature.baBridgerPsps.getActionClaim('edit') }`,
		`${ BridgerAdminFeature.baBridgerPsps.getActionClaim('create') }`,
		`${ BridgerAdminFeature.baBridgerPspSystemDetails }`,
		`${ BridgerAdminFeature.baBridgerPspSystemDetails.getActionClaim('edit') }`,
	],
	roles: [
		Role.baBridgerPspsManager.internalName,
	],
});

export const getBridgerPspsPublicInfoManagerMockCredentials = (): IdentityJwtSessionDTO => ({
	userIdentityId: '1353b684-cfee-41e6-a0e9-88b2280ce440',
	organizationId: 'bd313fe4-a7c4-43e0-9edb-b64d9b729ac1',
	userFullName: 'Bridger Psps Public Info Manager',
	userEmail: MockUserEmail.BridgerPspsPublicInfoManager,
	exp: getNeverExpiringEpoch(),
	sub: '1353b684-cfee-41e6-a0e9-88b2280ce440',
	aud: DEFAULT_JWT_STAGING_AUDIENCE,
	iss: DEFAULT_JWT_ISSUER,
	permissions: [
		`${ BridgerAdminFeature.baBridgerPsps }`,
		`${ BridgerAdminFeature.baBridgerPsps.getActionClaim('edit') }`,
		`${ BridgerAdminFeature.baBridgerPsps.getActionClaim('create') }`,
	],
	roles: [
		Role.baBridgerPspsPublicInfoManager.internalName,
	],
});

export const getSupportAgentTier0MockCredentials = (isBridgerAdmin: boolean): IdentityJwtSessionDTO => ({
	userIdentityId: '1dc0b34e-22b8-48a0-95fa-72a48b3cdc41',
	organizationId: 'bd313fe4-a7c4-43e0-9edb-b64d9b729ac1',
	userFullName: 'Support Agent Tier 0',
	userEmail: MockUserEmail.SupportAgentTier0,
	exp: getNeverExpiringEpoch(),
	sub: '1dc0b34e-22b8-48a0-95fa-72a48b3cdc41',
	aud: DEFAULT_JWT_STAGING_AUDIENCE,
	iss: DEFAULT_JWT_ISSUER,
	permissions: isBridgerAdmin
		? [
			`${ BridgerAdminFeature.baSupportRequests }`,
			`${ BridgerAdminFeature.baSupportRequests.getActionClaim('generateOrganizationAccessToken') }`,
		]
		: [
			`${ MerchantAdminFeature.checkouts }`,
			`${ MerchantAdminFeature.transactions }`,
			`${ MerchantAdminFeature.paymentRoutes }`,
			`${ MerchantAdminFeature.paymentRouteCredentials }`,
			`${ MerchantAdminFeature.paymentRouteRules }`,
			`${ MerchantAdminFeature.payouts }`,
			`${ MerchantAdminFeature.creditCardTokens }`,
			`${ MerchantAdminFeature.psps }`,
			`${ MerchantAdminFeature.pspCredentials }`,
		],
	roles: [
		Role.baSupportAgentTier0.internalName,
	],
});

export const getSupportAgentTier1MockCredentials = (isBridgerAdmin: boolean): IdentityJwtSessionDTO => ({
	userIdentityId: '1dc0b34e-22b8-48a0-95fa-72a48b3cdc41',
	organizationId: 'bd313fe4-a7c4-43e0-9edb-b64d9b729ac1',
	userFullName: 'Support Agent Tier 1',
	userEmail: MockUserEmail.SupportAgentTier1,
	exp: getNeverExpiringEpoch(),
	sub: '1dc0b34e-22b8-48a0-95fa-72a48b3cdc41',
	aud: DEFAULT_JWT_STAGING_AUDIENCE,
	iss: DEFAULT_JWT_ISSUER,
	permissions: isBridgerAdmin
		? [
			`${ BridgerAdminFeature.baSupportRequests }`,
			`${ BridgerAdminFeature.baSupportRequests.getActionClaim('edit') }`,
			`${ BridgerAdminFeature.baSupportRequests.getActionClaim('generateOrganizationAccessToken') }`,
			`${ BridgerAdminFeature.baOrganizations }`,
			`${ BridgerAdminFeature.baUsers }`,
			`${ BridgerAdminFeature.baUsers.getActionClaim('liftPermanentLock') }`,
			`${ BridgerAdminFeature.baUsers.getActionClaim('deleteEmailSuppression') }`,

			`${ BridgerAdminFeature.baMembers }`,
		]
		: [
			`${ MerchantAdminFeature.checkouts }`,
			`${ MerchantAdminFeature.transactions }`,
			`${ MerchantAdminFeature.paymentRoutes }`,
			`${ MerchantAdminFeature.paymentRouteCredentials }`,
			`${ MerchantAdminFeature.paymentRouteRules }`,
			`${ MerchantAdminFeature.payouts }`,
			`${ MerchantAdminFeature.creditCardTokens }`,
			`${ MerchantAdminFeature.psps }`,
			`${ MerchantAdminFeature.pspCredentials }`,
		],
	roles: [
		Role.baSupportAgentTier1.internalName,
	],
});

export const getSupportAgentTier2MockCredentials = (isBridgerAdmin: boolean): IdentityJwtSessionDTO => ({
	userIdentityId: 'd8379848-c6e1-4f9c-82be-bdf55b0e7b44',
	organizationId: 'bd313fe4-a7c4-43e0-9edb-b64d9b729ac1',
	userFullName: 'Support Agent Tier 2',
	userEmail: MockUserEmail.SupportAgentTier2,
	exp: getNeverExpiringEpoch(),
	sub: 'd8379848-c6e1-4f9c-82be-bdf55b0e7b44',
	aud: DEFAULT_JWT_STAGING_AUDIENCE,
	iss: DEFAULT_JWT_ISSUER,
	permissions: isBridgerAdmin
		? [
			...getSupportAgentTier1MockCredentials(isBridgerAdmin).permissions!,
			`${ BridgerAdminFeature.baUsers.getActionClaim('edit') }`,
			`${ BridgerAdminFeature.baUsers.getActionClaim('resetSecurityQuestions') }`,
		]
		: [
			...getSupportAgentTier1MockCredentials(isBridgerAdmin).permissions!,

			`${ MerchantAdminFeature.dashboard }`,
			`${ MerchantAdminFeature.members }`,
			`${ MerchantAdminFeature.transactions }`,
			`${ MerchantAdminFeature.reports }`,
			`${ MerchantAdminFeature.virtualTerminal }`,
		],
	roles: [
		Role.baSupportAgentTier2.internalName,
	],
});
export const getBridgerAdminMockUserCredentials = (): IdentityJwtSessionDTO => ({
	userIdentityId: '59627e89-8f55-4b55-940f-1676130a8f2a',
	organizationId: 'bd313fe4-a7c4-43e0-9edb-b64d9b729ac1',
	userFullName: 'Bridger Admin',
	userEmail: MockUserEmail.BridgerAdmin,
	exp: getNeverExpiringEpoch(),
	sub: '59627e89-8f55-4b55-940f-1676130a8f2a',
	aud: DEFAULT_JWT_STAGING_AUDIENCE,
	iss: DEFAULT_JWT_ISSUER,
	permissions: [
		`${ BridgerAdminFeature.baSupportRequests }`,
		`${ BridgerAdminFeature.baSupportRequests.getActionClaim('edit') }`,
		`${ BridgerAdminFeature.baSupportRequests.getActionClaim('generateOrganizationAccessToken') }`,
		`${ BridgerAdminFeature.baMembers }`,
		`${ BridgerAdminFeature.baMembers.getActionClaim('edit') }`,
		`${ BridgerAdminFeature.baMembers.getActionClaim('invite') }`,
		`${ BridgerAdminFeature.baOrganizations }`,
		`${ BridgerAdminFeature.baOrganizations.getActionClaim('edit') }`,
		`${ BridgerAdminFeature.baOrganizations.getActionClaim('inviteMembers') }`,
		`${ BridgerAdminFeature.baOrganizations.getActionClaim('changeMemberRole') }`,
		`${ BridgerAdminFeature.baUsers }`,
		`${ BridgerAdminFeature.baUsers.getActionClaim('edit') }`,
		`${ BridgerAdminFeature.baUsers.getActionClaim('liftPermanentLock') }`,
		`${ BridgerAdminFeature.baUsers.getActionClaim('deleteEmailSuppression') }`,
		`${ BridgerAdminFeature.baUsers.getActionClaim('resetSecurityQuestions') }`,
		`${ BridgerAdminFeature.baBridgerPsps }`,
		`${ BridgerAdminFeature.baBridgerPsps.getActionClaim('edit') }`,
		`${ BridgerAdminFeature.baBridgerPsps.getActionClaim('create') }`,
		`${ BridgerAdminFeature.baBridgerPsps.getActionClaim('delete') }`,
		`${ BridgerAdminFeature.baBridgerPspSystemDetails }`,
		`${ BridgerAdminFeature.baBridgerPspSystemDetails.getActionClaim('edit') }`,
		`${ BridgerAdminFeature.baBillingOperations }`,
		`${ BridgerAdminFeature.baBillingOperations.getActionClaim('edit') }`,
	],
	roles: [
		Role.baAdmin.internalName,
	],
});

export const getMerchantAdminMockUsersCredentials = () => ({
	[MockUserEmail.NoSubscription]: getNoSubscriptionMockUserCredentials(),
	[MockUserEmail.Free]: getFreeMockUserCredentials(),
	[MockUserEmail.FreeBlocked]: getFreeBlockedUserCredentials(),
	[MockUserEmail.Startup]: getStartupUserCredentials(),
	[MockUserEmail.Pro]: getProMockUserCredentials(),
	[MockUserEmail.ProEmailOtp]: getProEmailOtpMockUserCredentials(),
	[MockUserEmail.Enterprise]: getEnterpriseMockUserCredentials(),
	[MockUserEmail.EnterpriseUnpaid]: getEnterpriseUnpaidMockUserCredentials(),
	[MockUserEmail.Demo]: getDemoMockUserCredentials(),
	[MockUserEmail.SupportAgentTier0]: getSupportAgentTier0MockCredentials(false),
	[MockUserEmail.SupportAgentTier1]: getSupportAgentTier1MockCredentials(false),
	[MockUserEmail.SupportAgentTier2]: getSupportAgentTier2MockCredentials(false),
});

export const getBridgerAdminMockUsersCredentials = () => ({
	[MockUserEmail.BridgerAdmin]: getBridgerAdminMockUserCredentials(),
	[MockUserEmail.BridgerPspsManager]: getBridgerPspsManagerMockCredentials(),
	[MockUserEmail.BridgerPspsPublicInfoManager]: getBridgerPspsPublicInfoManagerMockCredentials(),
	[MockUserEmail.SupportAgentTier0]: getSupportAgentTier0MockCredentials(true),
	[MockUserEmail.SupportAgentTier1]: getSupportAgentTier1MockCredentials(true),
	[MockUserEmail.SupportAgentTier2]: getSupportAgentTier2MockCredentials(true),
});

export const getMockUsersCredentials = () => ({
	...getMerchantAdminMockUsersCredentials(),
	...getBridgerAdminMockUsersCredentials(),
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export function getIdentityJwtSession({ Authorization }: Record<string, string>): IdentityJwtSessionDTO {
	return JwtToken.decode<IdentityJwtSessionDTO>(Authorization.replace('Bearer ', ''));
}
